.container {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.headerButton {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.icon {
	margin-left: 10px;
	opacity: 0.3;
}

.iconSelected {
	margin-left: 10px;
	opacity: 1;
}
