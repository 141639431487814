.container {
	display: flex;
	flex-direction: column;
}

.headerContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
