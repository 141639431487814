.button {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-style: solid;
	border-width: 1px;
	border-radius: 1000px;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	width: 44px;
	height: 44px;
	background-color: white;
	border-color: black;
}

.focused {
	background-color: black;
}

.img {
	width: 22px;
	height: 22px;
}
