.marker {
	background-color: transparent;
	width: 50px;
	height: 50px;
	cursor: pointer;
}

.popup {
	:global(.mapboxgl-popup-content) {
		background-color: black;
		border-radius: 8px;
		padding: 8px 16px;
	}

	:global(.mapboxgl-popup-tip) {
		border-bottom-color: black;
	}
}

.popupContent {
	display: flex;
	flex-direction: column;
}
