.container {
	display: flex;
	flex: 1;
	flex-direction: column;
	padding-top: 25px;
	padding-left: 25px;
	padding-right: 50px;
}

.headerContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}
