.container {
	position: relative;
	width: 100%;
	height: 100%;
}

.focusButtonContainer {
	position: absolute;
	top: 32px;
	right: 32px;
	width: 44px;
	height: 44px;
}

.bottomInfoBarContainer {
	pointer-events: none;
	position: absolute;
	bottom: 32px;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: flex-end;
}
