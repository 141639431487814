.container {
	display: flex;
	flex: 1;
}

.sidebar {
	width: 200px;
	margin-top: 25px;
	margin-left: 50px;
}

.outletContainer {
	display: flex;
	flex: 1;
	overflow-y: scroll;
}
