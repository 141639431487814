.markerContainer {
	background-color: transparent;
	padding: 4px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background-color: #0000000d;
	border-radius: 40px;
	box-shadow: 0 0 10px 6px #0000000d;
}

.arrow {
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;

	border-top: 7px solid white;
}

.contentContainer {
	height: 58px;
	border-radius: 29px;
	background-color: white;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.image {
	width: 50px;
	height: 50px;
	border-radius: 25px;
	margin: 4px;
}

.batteryContainer {
	margin-left: 4px;
	margin-right: 4px;
	display: flex;
	align-items: center;
	position: relative;
}

.zap {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.battery {
	width: 20px;
	height: 10px;
	border: 1px solid black;
	border-radius: 2px;
	display: flex;
}

.batteryFill {
	margin: 1px;
	border-radius: 1px;
}

.batteryTip {
	margin-left: 1px;
	width: 1px;
	height: 4px;
	background-color: black;
	border-radius: 0px 1px 1px 0;
}

.percentContainer {
	margin-right: 8px;
}
