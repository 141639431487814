.container {
	width: 75%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #00000099;
	border-radius: 999px;
	padding: 8px 24px;
	width: 80%;
}

.pinImageContainer {
	display: flex;
	margin-right: 16px;
	margin-right: 24px;
}
.pinImage {
	width: 20px;
	height: 20px;
}

.contentContainer {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
}
