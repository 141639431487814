.container {
}

.userContainer {
	display: flex;
}

.userDetailsItemContainer {
	display: flex;
	flex-direction: column;
	margin-right: 10px;
	margin-left: 10px;
}
